<template>
  <body class="overflow-hidden">
    <v-app-bar class="mb-2" absolute color="white" scroll-target="#scrolling-techniques-7">
      
      <v-app-bar-nav-icon color="#282c2e" @click.stop="drawer = !drawer"><fai icon="bars"/></v-app-bar-nav-icon>

      <v-toolbar-title><v-img :src="$store.getters.get_logo"></v-img></v-toolbar-title>
  
        <v-spacer></v-spacer>
  
        <v-btn icon @click="$router.push('/notifications/'+1)">
          <v-badge :content="$store.getters.get_notice.toString()"><fai icon="bell"/></v-badge>
        </v-btn>
  
        <v-btn icon @click.stop="$store.dispatch('logout')">
         <fai icon="lock"/>
        </v-btn>
    </v-app-bar>

    <v-container id="scrolling-techniques-7" class="mt-6 overflow-y-auto">
      <!--
  START OF WEB PAGE
      -->

 <div class="container">
    <h1 class="ml2">Proudly Anambra...</h1>
        <v-container>
            <div :style="{display:'flex',flexFlow:'row wrap' ,justifyContent:'center'}">
                <div class="mx-3">
                    <div class="maincard mx-3">
                       
                            <div class="mt-5"><fai class="icon" :color="theme" icon="user-circle"/></div>
                            <div>{{total_registered_users}}</div>
                            <div class="txt">Customers</div>
                       
                    </div>
                </div>

                 <div  class="mx-3">
                     <div class="maincard mx-3">
                       
                            <div class="mt-5"><fai  class="icon" :color="theme" icon="credit-card"/></div>
                            <div>{{$store.getters.get_trans}}</div>
                            <div class="txt">Transactions</div>
                      
                    </div>
                 </div>

                  <div  class="mx-3">
                      <div class="maincard mx-3">
                       
                            <div class="mt-5"><fai  class="icon" :color="theme" icon="bell"/></div>
                            <div>{{$store.getters.get_notice}}</div>
                            <div class="txt">Notifications</div>
                      
                    </div>
                  </div>
            </div>
        </v-container>
    </div>

      <!--
  END OF WEB PAGE
      -->
    </v-container>

<v-navigation-drawer
        v-model="drawer"
        absolute
        left
        temporary
        dark
        color="#fe7b00"
      >
        <v-list
          nav
          dense
        >
         <div class="d-flex justify-start" :style="{fontSize:'50px',color:'#282c2e'}">
            <fai icon="dashboard"/>
            <span class="title mx-2 mt-4" :style="{color:'#282c2e',fontStyle:'italic'}">welcome admin</span>
         </div>
          <v-list-item-group
            active-class="deep-purple--text text--accent-4"
          >
            <v-list-item v-for="(item , index) in sideMenu" :key="index" @click="switchMenu(item.name)">
              <v-list-item-icon><fai :icon="item.icon" class="mt-1"/></v-list-item-icon>
              <v-list-item-content>{{item.name}}</v-list-item-content>
            </v-list-item>
  
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>

  </body>
  
</template>

<script>
import anime from 'animejs/lib/anime.es.js'
export default {
    data:()=>{
        return{
            overlay:false,
            drawer: false,
            selectedMenu:'Home',
            sideMenu:[
              
            ],
            total_registered_users:0,
            total_transactions:0,
            total_notifications:0,
            theme:'#fe7b00',
        }
    },
    components:{
  
    },
    methods:{
    fetchTotalUsers(){
      this.axios({
          url:this.$store.getters.get_api+"fetchTotalUsers",
          method:"get",
          headers:{
              "Access-Control-Allow-Origin":"*"
          }
      }).then((res)=>{
          const data = res.data;
          this.total_registered_users = data.message
      }).catch((error)=>{
          return error;
      });
      },

     switchMenu(name){
        if(this.$router.currentRoute.path!=='/'+name.toLowerCase())
        {
          this.$router.push('/'+name.toLowerCase());
        }
     },
     
     logout(){
      
      this.axios({
        url:this.$store.getters.get_api+"adminLogout",
        method:"get",
        headers:{
          'Access-Control-Allow-Origin':'*',
          'Authorization':`Bearer ${this.$store.getters.get_token}`
        }
      }).then((res)=>{
        const data = res.data;
        if(!data.error){
          this.$store.commit("set_token" , undefined);
          this.$router.push("/")
        }
      }).catch((error)=>{
        return error
      })
     }
    },
    created(){

    },
    mounted(){
      this.sideMenu=this.$store.getters.get_menu;
       var textWrapper = document.querySelector('.ml2');
      textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");

      anime.timeline({loop: true})
        .add({
          targets: '.ml2 .letter',
          scale: [4,1],
          opacity: [0,1],
          translateZ: 0,
          easing: "easeOutExpo",
          duration: 950,
          delay: (el, i) => 70*i
        }).add({
          targets: '.ml2',
          opacity: 0,
          duration: 1000,
          easing: "easeOutExpo",
          delay: 1000
        });

        this.fetchTotalUsers();
       this.$store.dispatch('totalNotice');
       this.$store.dispatch('totalTransaction')
    },
    watch:{
       
        //END OF WATCH
    }
}
</script>
<style scoped>
*{
    border:0px;
}
.overflow-y-auto{
  min-width:100%;
  min-height:100%;
  background:#e0e0e0;
}
.icon-1{
    font-size: 50px;
}
body{
    width:100%;
    height:100%;
    background:#e0e0e0;
}




@font-face {
font-family: "raleway";
src: url(../assets/Raleway-Bold.ttf) format("truetype"),
/*url("CustomFont.otf") format("opentype"),
url("CustomFont.svg#filename") format("svg");*/
}
.icon{
    font-size:40px;
}
.maincard{
    border-radius:10px;
    box-shadow:16px 16px 40px #bebebe,
                -16px -16px 40px #ffffff;
    text-align:center;
    display:flex;
    flex-direction:column;
    justify-content:center;
    padding:4px;
}
.container{
    min-width:100%;
    min-height:100%;
}
.txt{
    font-family: "raleway";
}
.ml2 {
  font-weight: 900;
   text-align: center;
}

.ml2 .letter {
  display: inline-block;
  line-height: 1em;

}

@media screen and (max-width:600px){
.maincard{
    width:250px;
    height:250px;
    margin-bottom:1em;
}

.ml2{
  font-size: 3.5em;
   margin-bottom: 0.2em;
   color:#fe7b00;
}

}



@media screen and (min-width:650px){
.text1,.text2{
    font-size: 80px;
}

.ml2{
  font-size: 4em;
   margin-bottom: 1.2em;
   margin-top: 1em;
   color:#fe7b00;
}
.maincard{
    width:250px;
    height:200px;
}

}
.txt{
    font-family:sans-serif;
    font-size:30px;
}



</style>